import produce from "immer";

const adminappState = {
  //ADMIN
  loadAdm: false,
  keywordSearchAdm: false,
  metaAdm: {
    limit: 25,
    current_items: 0,
    total_items: 0,
    offset: 1,
    current_page: 1,
    total_pages: 0,
  },
  dataAdm: {
    data: [],
  },
};

function adminappReducer(state = adminappState, action) {
  let nextState;

  switch (action.type) {
    case "SetLoadAdmin":
      nextState = produce(state, (draftState) => {
        draftState.loadAdm = action.value;
      });

      return nextState || state;

    case "SetDataAdmin":
      nextState = produce(state, (draftState) => {
        draftState.dataAdm.data = action.value.items;
        draftState.metaAdm = action.value.meta;
      });

      return nextState || state;

    case "SetRowPerPageAdmin":
      nextState = produce(state, (draftState) => {
        draftState.metaAdm.limit = action.value;
      });

      return nextState || state;

    case "SetKeywordSearchAdmin":
      nextState = produce(state, (draftState) => {
        draftState.keywordSearchAdm = action.value;
      });

      return nextState || state;

    case "DelAdmin":
      let indexAdminDel = state.dataAdm.data.findIndex(
        (user) => user.id == action.value,
      );

      nextState = produce(state, (draftState) => {
        draftState.dataAdm.data.splice(indexAdminDel, 1);
      });

      return nextState || state;

    case "AddAdmin":
      nextState = produce(state, (draftState) => {
        draftState.dataAdm.data.push(action.value);
      });

      return nextState || state;

    case "PutAdmin":
      let indexAdminPut = state.dataAdm.data.findIndex(
        (user) => user.id == action.value.id,
      );

      nextState = produce(state, (draftState) => {
        draftState.dataAdm.data[indexAdminPut] = action.value;
      });

      return nextState || state;

    default:
      return state;
  }
}

export default adminappReducer;
