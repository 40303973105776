import "./list.scss";
import Datatable from "../../components/datatable/Datatable";

const List = ({ columns, screen }) => {
  return (
    <div className="list">
      <div className="listContainer">
        <Datatable columns={columns} screen={screen} />
      </div>
    </div>
  );
};

export default List;
