import React from "react";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import InputPasswordToggle from "../../../@core/components/input-password-toggle";
import { useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useAuthUser } from "react-auth-kit";

// ** Reactstrap Imports
import { Button, Label, FormText, Form, Input } from "reactstrap";

const ModifAdminForm = ({ modify, datas }) => {
  const schema = yup
    .object()
    .shape(
      {
        last_name: yup
          .string()
          .required("le nom est requis")
          .matches(
            /^[^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/,
            "Entrez un nom valide",
          ),
        first_name: yup
          .string()
          .required("le prenom est requis")
          .matches(
            /^[^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/,
            "Entrez un prenom valide",
          ),
        email: yup
          .string()
          .required("le mail est requis")
          .email("Entrez un email valide"),
        telephone: yup
          .string()
          .required("le numéro est requis")
          .min(10, "Entrez un numéro valide"),
        role: yup.string().required("le role est requis"),
      },
      [],
    )
    .required();

  const auth = useAuthUser();

  const roleOptions = [
    { label: "GM", value: "GM" },
    { label: "GMA", value: "CA" },
    { label: "EV", value: "EM" },
    { label: "RMC", value: "RMC" },
  ];

  // ** Vars
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({
    defaultValues: {
      last_name: datas.last_name,
      first_name: datas.first_name,
      email: datas.email,
      telephone: datas.telephone,
      role: datas.role,
    },
    resolver: yupResolver(schema),
  });

  const dispatch = useDispatch();

  // ** Function to handle form submit
  const onSubmit = async (data) => {
    console.log(datas);
    dispatch({
      type: "setDialog",
      payload: {
        active: true,
        view: "Add Admin",
        value: { ...data, id: datas.id },
      },
    });
  };

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-1">
          <Label className="form-label" for="fullName">
            Nom <span className="text-danger">*</span>
          </Label>
          <Controller
            name="last_name"
            control={control}
            render={({ field }) => (
              <Input
                id="last"
                placeholder="Doe"
                invalid={errors?.last_name && true}
                {...field}
                disabled={["CA", "RMC"].includes(auth().role) ? true : false}
              />
            )}
          />
        </div>

        <div className="mb-1">
          <Label className="form-label" for="fullName">
            Prenoms <span className="text-danger">*</span>
          </Label>
          <Controller
            name="first_name"
            control={control}
            render={({ field }) => (
              <Input
                id="first_name"
                placeholder="John"
                invalid={errors?.first_name && true}
                {...field}
                disabled={["CA", "RMC"].includes(auth().role) ? true : false}
              />
            )}
          />
        </div>

        <div className="mb-1">
          <Label className="form-label" for="userEmail">
            Email <span className="text-danger">*</span>
          </Label>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Input
                type="email"
                id="userEmail"
                placeholder="john.doe@example.com"
                invalid={errors?.email && true}
                {...field}
                disabled={["CA", "RMC"].includes(auth().role) ? true : false}
              />
            )}
          />
        </div>

        <div className="mb-1">
          <Label className="form-label" for="contact">
            Contact <span className="text-danger">*</span>
          </Label>
          <Controller
            name="telephone"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <PhoneInput
                {...field}
                country={"fr"}
                disabled={["CA", "RMC"].includes(auth().role) ? true : false}
              />
            )}
          />
          <small className="error tw-mt-1 text-danger">
            {errors.telephone?.message}
          </small>
        </div>

        <div className="mb-1 tw-z-[955] ">
          <Label className="form-label" for="role">
            Choisir le rôle
          </Label>
          <Controller
            name="role"
            control={control}
            render={({ field: { onChange, value, ref, name } }) => (
              <Select
                isClearable={false}
                isSearchable={false}
                inputRef={ref}
                classNamePrefix="select"
                options={roleOptions}
                value={roleOptions.find((c) => c.value === value)}
                onChange={(val) => onChange(val.value)}
                isDisabled={["CA", "RMC"].includes(auth().role) ? true : false}
              />
            )}
          />
          <small className="error tw-mt-1 text-danger">
            {errors.role?.message}
          </small>
        </div>

        {auth().role === "GM" && isDirty && isValid && (
          <Button type="submit" className="me-1">
            Modifier l'utilisateur
          </Button>
        )}
      </Form>
    </div>
  );
};

export default ModifAdminForm;
