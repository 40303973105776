import { useState } from "react";
import { useDispatch, batch } from "react-redux";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import { NotificationManager } from "react-notifications";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { CMSApi } from "../../../api/cmsApi";
import { Input, InputGroup } from "reactstrap";

const ModifFaq = ({ data }) => {
  const dispatch = useDispatch();

  const [load, setload] = useState(false);

  const schema = yup
    .object({
      question: yup.string().required("Entrez une question"),
      english_question: yup.string().required("Entrez une question"),
      answer: yup.string().required("Entrez une réponse"),
      english_answer: yup.string().required("Entrez une réponse"),
    })
    .required();

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      question: data.question,
      answer: data.answer,
      english_question: data.english_question,
      english_answer: data.english_answer,
    },
  });

  const handleClose = () => {
    dispatch({ type: "setDialog", payload: { active: false, view: null } });
  };

  const onSubmit = async (payload) => {
    setload(true);

    const res = await CMSApi.putFAQ({
      id: data.id,
      ...payload,
    });

    setload(false);

    if (res.success) {
      batch(() => {
        dispatch({ type: "setDialog", payload: { active: false, view: null } });
        dispatch({ type: "PutFaq", value: res.data.data });
      });

      NotificationManager.success("Faq modifié avec succès ", "", 3000);
    } else {
      NotificationManager.error(
        "Erreur lors de l'envoie du code. Vérifier votre connexion puis Réessayer",
        "",
        3000,
      );
    }
  };

  return (
    <>
      <DialogTitle
        sx={{
          backgroundColor: "#E7075B",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <span className="tw-font-bold tw-text-[15px] tw-text-white">
          MODIFIER FAQ
        </span>
      </DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div className="tw-text-md tw-mt-3">
            Êtes-vous sûr(e) de vouloir modifier le FAQ ?<br />
          </div>

          <div className="tw-flex tw-gap-3">
            <InputGroup>
              <Controller
                name="question"
                control={control}
                render={({ field }) => (
                  <Input
                    className="tw-mt-3"
                    invalid={errors?.question && true}
                    {...field}
                    placeholder="Question..."
                  />
                )}
              />
            </InputGroup>

            <InputGroup>
              <Controller
                name="answer"
                control={control}
                render={({ field }) => (
                  <Input
                    type="textarea"
                    invalid={errors?.answer && true}
                    {...field}
                    className="tw-mt-3"
                    placeholder="Réponse faq..."
                  />
                )}
              />
            </InputGroup>
          </div>

          <div className="tw-flex tw-gap-3">
            <InputGroup>
              <Controller
                name="english_question"
                control={control}
                render={({ field }) => (
                  <Input
                    className="tw-mt-3"
                    invalid={errors?.english_question && true}
                    {...field}
                    placeholder="Question en anglais"
                  />
                )}
              />
            </InputGroup>

            <InputGroup>
              <Controller
                name="english_answer"
                control={control}
                render={({ field }) => (
                  <Input
                    type="textarea"
                    invalid={errors?.english_answer && true}
                    {...field}
                    className="tw-mt-3"
                    placeholder="Réponse en anglais"
                  />
                )}
              />
            </InputGroup>
          </div>
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <button
          disabled={load}
          className="tw-text-sm tw-font-semibold tw-h-[35px] tw-px-3 tw-rounded-md tw-text-slate-700"
          onClick={() => handleClose()}
        >
          NON
        </button>
        <button
          disabled={load}
          className="tw-text-sm tw-font-semibold tw-h-[35px] tw-px-3 tw-rounded-md tw-text-white tw-bg-[#E7075B]"
          onClick={handleSubmit(onSubmit)}
        >
          {load ? (
            <CircularProgress size={16} sx={{ color: "white" }} />
          ) : (
            "OUI"
          )}
        </button>
      </DialogActions>
    </>
  );
};

export default ModifFaq;
