import { useLocation, useParams } from "react-router-dom";
import "../../assets/forms.scss";
import "./new.scss";
import CircularProgress from "@mui/material/CircularProgress";
import { NotificationManager } from "react-notifications";
import Select from "react-select";
import { useState, useEffect } from "react";
import axios from "axios";
import useFetch from "../../hooks/useFetch";
import { Label } from "reactstrap";

const lockerType = ["xs", "s", "m", "l", "xl", "mb"];

const NewContainer = ({ inputs, title }) => {
  let { id } = useParams();
  const location = useLocation();

  const path = location.pathname.split("/")[1];
  const { data, loading, error } = useFetch(`/${path}/${id}`);

  const [info, setInfo] = useState({});

  const [load, setload] = useState(false);

  // set stock
  const [stocks, setStocks] = useState({});
  const [lockerUid, setLockersUid] = useState(null);

  useEffect(() => {
    if (data) {
      const { serial_nb, status, type, name, connected, stock } = data;
      setInfo({ serial_nb, status, type, name, connected, stock });
      if (stock) setStocks(stock);
    }
  }, [data]);

  const changeStocks = (e, type) => {
    // if target value negative, set input to 0
    if (e.target.value < 0) {
      return (e.target.value = 0);
    }
    setStocks((prev) => ({ ...prev, [type]: e.target.value }));
  };

  const handleChange = (e) => {
    setInfo((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const handleClick = async (e) => {
    e.preventDefault();

    setload(true);

    // validate all inputs if fullfil
    if (Object.values(info).length < 4) {
      NotificationManager.error(
        "Veuillez renseigner tous les champs",
        "Error",
        5000,
      );
      return;
    }
    // checke if all information is valid?
    if (Object.values(info).filter((i) => i === "").length > 0) {
      NotificationManager.error(
        "Veuilez vous assurer que toutes les inforrmations sont correctes",
        "Error",
        5000,
      );
      return;
    }

    info.stock = Object.entries(stocks);

    try {
      await axios.put(
        `${process.env.REACT_APP_PUBLIC_URL}/containers/` + id,
        info,
      );
    } catch (err) {
      setload(false);
      return NotificationManager.error(err.message, "Error", 5000);
    }

    NotificationManager.success(
      "Container édité avec success.",
      "Success",
      5000,
    );

    setload(false);
  };

  if (loading) {
    return (
      <div className="w-100 mt-4 tw-mb-10 d-flex flex-column justify-content-center align-items-center ">
        <CircularProgress sx={{ color: "#E7075B" }} />
      </div>
    );
  }

  return (
    <div className="new">
      <div className="newContainer">
        <div className="top">
          <h1>Editer le container</h1>
        </div>
        <div className="bottom">
          <form>
            <Label className="tw-mt-3 tw-text-xs">Nom du container</Label>
            <input
              className="tw-h-[42px] tw-text-[13px]  tw-border tw-w-full tw-px-3 tw-text-gray-700 tw-leading-tight focus:tw-outline-none focus:tw-shadow-outline"
              onChange={handleChange}
              type="text"
              placeholder="Nom du container"
              id="name"
              value={info.name}
            />

            <Label className="tw-mt-3 tw-text-xs">
              Numero de serie du container
            </Label>
            <input
              className="tw-h-[42px] tw-text-[13px]  tw-border tw-w-full tw-px-3 tw-text-gray-700 tw-leading-tight focus:tw-outline-none focus:tw-shadow-outline"
              onChange={handleChange}
              type="text"
              placeholder="009488555 B0000"
              id="serial_nb"
              value={info.serial_nb}
            />

            <Label className="tw-mt-3 tw-text-xs">Container connecté ?</Label>
            <Select
              options={[
                { value: true, label: "Oui" },
                { value: false, label: "Non" },
              ]}
              onChange={(e) =>
                setInfo((prev) => ({ ...prev, connected: e.value }))
              }
              value={{
                value: info.connected,
                label: info.connected ? "Oui" : "Non",
              }}
            />

            <Label className="tw-mt-3 tw-text-xs">
              Courte description du container
            </Label>
            <input
              className="tw-h-[42px] tw-text-[13px]  tw-border tw-w-full tw-px-3 tw-text-gray-700 tw-leading-tight focus:tw-outline-none focus:tw-shadow-outline"
              onChange={handleChange}
              type="text"
              placeholder="Type du container"
              id="type"
              value={info.type}
            />

            <hr />
            <div className="lockersInputs">
              <h3>Definir les Stocks des lockers</h3>
              <br />
              <div className="stocks-locker">
                {lockerType.map((type, id) => (
                  <>
                    <Label key={id} className="tw-mt-3 tw-text-xs">
                      {type === "mb" ? "Réfrigéré" : type.toUpperCase()}{" "}
                    </Label>

                    <input
                      className="tw-h-[42px] tw-text-[13px]  tw-border tw-w-full tw-px-3 tw-text-gray-700 tw-leading-tight focus:tw-outline-none focus:tw-shadow-outline"
                      type="number"
                      value={stocks[type] ? `${stocks[type]}` : ""}
                      onChange={(e) => changeStocks(e, type)}
                    />
                  </>
                ))}
              </div>
              <hr />
            </div>
            <br />
            <button
              disabled={load}
              type="submit"
              className="tw-text-sm tw-font-semibold tw-h-[35px] tw-px-3 tw-rounded-md tw-text-white btn-fushia"
              onClick={handleClick}
            >
              {load ? "Chargement ..." : "Editer le container"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NewContainer;
