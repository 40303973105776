import { useEffect, useState } from "react";
import useFetch from "../../hooks/useFetch";
import "./home.scss";
import Widget from "../../components/widget/Widget";
import Featured from "../../components/featured/Featured";
import Chart from "../../components/chart/Chart";
import Verticalchart from "../../components/chart/Verticalchart";
import Table from "../../components/table/Table";

const url = `${process.env.REACT_APP_PUBLIC_URL}/kpi/globalStats`;
const Home = () => {
  const [stats, setData] = useState(null);

  const { data, loading, error } = useFetch(`/kpi/globalStats`);

  useEffect(() => {
    if (data) {
      setData(data.data);
    }
  }, [data]);

  return (
    <div className="home">
      <div className="homeContainer">
        <div className="widgets">
          <Widget
            type="CA today"
            value={stats && stats.reservationStat.todayReservations}
            loading={loading}
          />
          <Widget
            type="Events"
            value={stats && stats.eventCount}
            loading={loading}
          />
          <Widget
            type="Réservations"
            value={stats && stats.reservationStat.count}
            loading={loading}
          />
          <Widget
            type="Remboursements"
            value={stats && stats.reservationStat.canceled.totalIncome}
            loading={loading}
          />
        </div>
        <div className="charts">
          <Chart
            title=" Revenus de l'année (Euro)"
            aspect={2 / 1}
            data={stats && stats.reservationStat.incomePerMonth}
          />
          <Verticalchart data={stats && stats.feedbacks} />
        </div>
        <div className="listContainer">
          <Featured
            value={stats && stats.reservationStat.payed}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
