export const userInputs = [
  {
    id: "first_name",
    label: "Nom",
    type: "text",
    placeholder: "john_doe",
  },
  {
    id: "last_name",
    label: "Prénom",
    type: "text",
    placeholder: "john_doe",
  },
  {
    id: "email",
    label: "Email",
    type: "email",
    placeholder: "john_doe@gmail.com",
  },
  {
    id: "telephone",
    label: "Telephone",
    type: "text",
    placeholder: "+1 234 567 89",
  },
  {
    id: "password",
    label: "Password",
    type: "password",
  },
];

export const eventInputs = [
  {
    id: "event",
    label: "Nom de l'evenement",
    type: "text",
    placeholder: "Nom de l'evenement ",
  },
  {
    id: "city",
    label: "Saisir la ville",
    type: "text",
    placeholder: "Ville de l'evenement",
  },
  {
    id: "web_site",
    label: "Site web de l'evenement",
    type: "text",
    placeholder: "Site web de l'evenement",
  },
];

export const containerInputs = [
  {
    id: "serial_nb",
    label: "Numero de serie du container",
    type: "text",
    placeholder: "009488555 B0000",
  },
  {
    id: "type",
    label: "Type du container",
    type: "text",
    placeholder: "Type du container",
  },
  {
    id: "name",
    label: "Nom du container",
    type: "text",
    placeholder: "Nom du container",
  },
];

export const organizationInputs = [
  {
    id: "address",
    label: "Adresse",
    type: "text",
    placeholder: "Adresse",
  },
  {
    id: "email",
    label: "Email",
    type: "email",
    placeholder: "@gmail.com",
  },
  {
    id: "phone",
    label: "Telephone",
    type: "text",
    placeholder: "+1 234 567 89",
  },
  {
    id: "total_amont",
    label: "Montant total (en €))",
    type: "number",
    placeholder: "Montant total",
  },
  {
    id: "loyal_form",
    label: "Forme de Jusridique",
    type: "text",
    placeholder: "Forme de Jusridique",
  },
  {
    id: "denomination",
    label: "Dénomination",
    type: "text",
    placeholder: "Dénomination",
  },
  {
    id: "siret",
    label: "Siret",
    type: "text",
    placeholder: "Siret",
  },
  {
    id: "zip",
    label: "Code postal",
    type: "text",
    placeholder: "Code postal",
  },
  {
    id: "city",
    label: "Ville",
    type: "text",
    placeholder: "Ville",
  },
  {
    id: "country",
    label: "Pays",
    type: "text",
    placeholder: "Pays",
  },
];

export const promoInputs = [
  {
    id: "generatedTime",
    label: "Nombre de code a generer",
    type: "number",
    placeholder: "Nombre de code a generer",
  },
  // {
  //   id: "discount",
  //   label: "Pourcentage de reduction",
  //   type: "number",
  //   placeholder: "Pourcentage de reduction",
  // }
];
