import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import EventIcon from "@mui/icons-material/Event";
import InboxIcon from "@mui/icons-material/Inbox";
import PeopleIcon from "@mui/icons-material/People";
import SellIcon from "@mui/icons-material/Sell";
import BurstModeIcon from "@mui/icons-material/BurstMode";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import WorkIcon from "@mui/icons-material/Work";
import HandshakeIcon from "@mui/icons-material/Handshake";
import QuizIcon from "@mui/icons-material/Quiz";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import AssignmentIcon from "@mui/icons-material/Assignment";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";

const SideMenu = [
  {
    group: "MAIN",
    access: ["GM", "CA"],
    menu: [
      {
        menuTitle: "Dashboard",
        access: ["GM", "RMC", "CA"],
        to: "/",
        icon: function () {
          return <DashboardIcon className="icon" />;
        },
      },
    ],
  },

  {
    group: "LISTS",
    access: ["GM", "CA", "EM", "RMC", "OG"],
    menu: [
      {
        menuTitle: "Utilisateurs",
        access: ["GM", "CA"],
        to: "/admin",
        icon: function () {
          return <PersonOutlineIcon className="icon" />;
        },
      },
      {
        menuTitle: "Evénements",
        access: ["GM", "RMC", "CA", "EM", "OG"],
        to: "/events",
        icon: function () {
          return <EventIcon className="icon" />;
        },
      },
      {
        menuTitle: "Conteneurs",
        access: ["GM", "CA"],
        to: "/containers",
        icon: function () {
          return <InboxIcon className="icon" />;
        },
      },
      {
        menuTitle: "Organisateurs",
        access: ["GM", "RMC", "CA", "EM"],
        to: "/organizers",
        icon: function () {
          return <PeopleIcon className="icon" />;
        },
      },
      {
        menuTitle: "Codes Promo",
        access: ["GM", "CA"],
        to: "/promo",
        icon: function () {
          return <SellIcon className="icon" />;
        },
      },
    ],
  },

  {
    group: "CMS",
    access: ["GM", "RMC", "CA"],
    menu: [
      {
        menuTitle: "Slider",
        access: ["GM", "RMC", "CA"],
        to: "/slides",
        icon: function () {
          return <BurstModeIcon className="icon" />;
        },
      },
      {
        menuTitle: "Liens Utiles",
        access: ["GM", "RMC", "CA"],
        to: "/links",
        icon: function () {
          return <InsertLinkIcon className="icon" />;
        },
      },
      {
        menuTitle: "Jobs",
        access: ["GM", "RMC", "CA"],
        to: "/jobs",
        icon: function () {
          return <WorkIcon className="icon" />;
        },
      },
      {
        menuTitle: "On Parle de Nous",
        access: ["GM", "RMC", "CA"],
        to: "/talkaboutus",
        icon: function () {
          return <RecordVoiceOverIcon className="icon" />;
        },
      },
      {
        menuTitle: "Partenaires",
        access: ["GM", "RMC", "CA"],
        to: "/partenaire",
        icon: function () {
          return <HandshakeIcon className="icon" />;
        },
      },
      {
        menuTitle: "Faq",
        access: ["GM", "RMC", "CA"],
        to: "/faq",
        icon: function () {
          return <QuizIcon className="icon" />;
        },
      },
      {
        menuTitle: "CGU",
        access: ["GM", "RMC", "CA"],
        to: "/cgu",
        icon: function () {
          return <AssignmentIcon className="icon" />;
        },
      },
      {
        menuTitle: "CGV",
        access: ["GM", "RMC", "CA"],
        to: "/cgv",
        icon: function () {
          return <TextSnippetIcon className="icon" />;
        },
      },
      {
        menuTitle: "RGPD",
        access: ["GM", "RMC", "CA"],
        to: "/rgpd",
        icon: function () {
          return <StickyNote2Icon className="icon" />;
        },
      },
      {
        menuTitle: "Mentions Legales",
        access: ["GM", "RMC", "CA"],
        to: "/mention",
        icon: function () {
          return <LocalPoliceIcon className="icon" />;
        },
      },
    ],
  },
];

export default SideMenu;
