import React, { useState } from "react";
import BreadCrumbs from "../../@core/components/breadcrumbs";
import {
  Row,
  Col,
  Form,
  Card,
  Input,
  Label,
  Button,
  CardBody,
  CardTitle,
  CardHeader,
  FormFeedback,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useAuthUser, useSignIn } from "react-auth-kit";
import { AuthApi } from "../../api/authApi";
import { NotificationManager } from "react-notifications";
import PasswordSettings from "./putPassword";

const AccountSettings = () => {
  const auth = useAuthUser();
  const signIn = useSignIn();

  const schema = yup
    .object()
    .shape(
      {
        nom: yup.string().required("le nom est requis"),
        prenom: yup.string().required("le prenom est requis"),
        telephone: yup.string().required("le numéro est requis"),
      },
      [],
    )
    .required();

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({
    defaultValues: {
      nom: auth().last_name,
      prenom: auth().first_name,
      telephone: auth().telephone,
    },
    resolver: yupResolver(schema),
  });

  const [load, setLoad] = useState(false);

  const onSubmit = async (data) => {
    setLoad(true);
    const res = await AuthApi.putProfil(
      {
        first_name: data.prenom,
        last_name: data.nom,
        telephone: data.telephone,
        email: auth().email,
        role: auth().role,
      },
      auth().id,
      auth().token,
    );
    setLoad(false);

    if (res.success) {
      NotificationManager.success("Profil modifié avec succès ", "", 3000);
      signIn({
        token: auth().token,
        expiresIn: 60,
        tokenType: "Bearer",
        authState: {
          ...auth(),
          ...res.data.data,
        },
        // refreshToken: res.data.refreshToken
      });
    } else {
      NotificationManager.error(`${res.message}`, "", 3000);
    }
  };

  return (
    <div className="datatable">
      <div className=" tw-w-full tw-my-3">
        <BreadCrumbs title="Profil de l'utilisateur" />

        <Row>
          <Col xs={12}>
            <Card>
              <CardHeader className="border-bottom">
                <CardTitle tag="h4">Profile</CardTitle>
              </CardHeader>
              <CardBody className="py-2 my-25">
                <Form className="mt-2 pt-50" onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col sm="6" className="mb-1">
                      <Label className="form-label" for="firstName">
                        Nom
                      </Label>
                      <Controller
                        name="nom"
                        control={control}
                        render={({ field }) => (
                          <Input
                            id="firstName"
                            placeholder="John"
                            invalid={errors.nom && true}
                            {...field}
                          />
                        )}
                      />
                      {errors && errors.nom && (
                        <FormFeedback>{errors.nom.message}</FormFeedback>
                      )}
                    </Col>

                    <Col sm="6" className="mb-1">
                      <Label className="form-label" for="lastName">
                        Prenom
                      </Label>
                      <Controller
                        name="prenom"
                        control={control}
                        render={({ field }) => (
                          <Input
                            id="lastName"
                            placeholder="Doe"
                            invalid={errors.prenom && true}
                            {...field}
                          />
                        )}
                      />
                      {errors.prenom && (
                        <FormFeedback>{errors.prenom.message}</FormFeedback>
                      )}
                    </Col>

                    <Col sm="6" className="mb-1">
                      <Label className="form-label" for="lastName">
                        Contact
                      </Label>
                      <Controller
                        name="telephone"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <PhoneInput
                            {...field}
                            country={"fr"}
                            className=" tw-w-full"
                          />
                        )}
                      />
                      {errors.telephone && (
                        <FormFeedback>{errors.telephone.message}</FormFeedback>
                      )}
                    </Col>
                  </Row>

                  <Button
                    disabled={load}
                    type="submit"
                    className="me-1 btn-fushia"
                  >
                    {load ? "Load..." : "Modifier le profile"}
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <PasswordSettings />
      </div>
    </div>
  );
};

export default AccountSettings;
