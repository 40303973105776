import { all } from "redux-saga/effects";
import { adminappSaga } from "./adminappSaga";
import { eventappSaga } from "./eventappSaga";
import { faqappSaga } from "./faqappSaga";

export function* rootSaga() {
  yield all([
    ...adminappSaga,
    ...eventappSaga,
    ...faqappSaga,
    // ...albumsappSaga,
    // ...usersappSaga
  ]);
}
