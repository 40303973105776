const baseUrl = process.env.REACT_APP_PUBLIC_URL;

export const AdminApi = {
  async getAllAdmin(data) {
    try {
      const response = await fetch(baseUrl + "/admin", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + data.token,
        },
      });
      const res = await response.json();

      if (response.ok) {
        return {
          success: true,
          data: res,
        };
      } else {
        return {
          success: false,
          message: res?.message,
        };
      }
    } catch (error) {
      return {
        success: false,
        message: "error",
      };
    }
  },

  async sendCodeDoubleAuth(data) {
    try {
      const response = await fetch(baseUrl + `/clients/otp/${data.phone}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        // body: JSON.stringify(logdata)
        // credentials: 'include'
      });

      const res = await response.json();

      if (response.ok) {
        return {
          success: true,
          data: res,
        };
      } else {
        return {
          success: false,
          message: res?.message,
        };
      }
    } catch (error) {
      return {
        success: false,
        message: "error",
      };
    }
  },

  async deleteAdmin(data) {
    try {
      const response = await fetch(baseUrl + `/admin/${data.id}`, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + data.token,
        },
        // body: JSON.stringify(logdata)
        // credentials: 'include'
      });
      const res = await response.json();

      if (response.ok) {
        return {
          success: true,
          data: res || null,
        };
      } else {
        return {
          success: false,
          message: res?.message || null,
        };
      }
    } catch (error) {
      return {
        success: false,
        message: "error",
      };
    }
  },

  async createAdmin(data, token) {
    try {
      const response = await fetch(baseUrl + `/admin`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
        // credentials: 'include'
      });
      const res = await response.json();

      if (response.ok) {
        return {
          success: true,
          data: res.data || null,
        };
      } else {
        return {
          success: false,
          message: res?.message || null,
        };
      }
    } catch (error) {
      return {
        success: false,
        message: "error",
      };
    }
  },

  async putAdmin(data, token) {
    try {
      const response = await fetch(baseUrl + `/admin/${data.id}`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          telephone: data.telephone,
          role: data.role,
        }),
        // credentials: 'include'
      });
      const res = await response.json();

      if (response.ok) {
        return {
          success: true,
          data: res.data || null,
        };
      } else {
        return {
          success: false,
          message: res?.message || null,
        };
      }
    } catch (error) {
      return {
        success: false,
        message: "error",
      };
    }
  },
};
