import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { createBrowserHistory } from "history";

import "./style/dark.scss";
import { useContext } from "react";
import { DarkModeContext } from "./context/darkModeContext";
import { RequireAuth, useAuthUser } from "react-auth-kit";

import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import ForgetMdp from "./pages/login/forgotMdp";
import List from "./pages/list/List";
import List2 from "./pages/list/List2";
import NewEvent from "./pages/events/NewEvent";
import EditEvent from "./pages/events/EditEvent";
import ViewEvent from "./pages/events/viewEvent";
import NewContainer from "./pages/containers/NewContainer";
import EditContainer from "./pages/containers/EditContainer";
import NewOrganizer from "./pages/organisateurs/NewOrganizer";
import EditOrganizer from "./pages/organisateurs/EditOrganizer";
import NewPromo from "./pages/promo/NewPromo";
import EditPromo from "./pages/promo/EditPromo";
import NotFound from "./pages/notFound";

import Cgu from "./pages/cms/cgu";
import Cgv from "./pages/cms/cgv";
import Edit from "./pages/cms/edit";
import Rgpd from "./pages/cms/rgpd";
import MentionLegal from "./pages/cms/mentionLegal";
import Faq from "./pages/cms/faq";
import Links from "./pages/cms/links";
import Job from "./pages/cms/jobs";
import TalkAboutUs from "./pages/cms/talkaboutus";
import Partenaire from "./pages/cms/partenaire";
import ResetMdp from "./pages/login/resetpwd";
import AccountSettings from "./pages/settings/account-settings";

// cms
import SliderList from "./pages/cms/SliderList";
import NewSlider from "./pages/cms/NewSlider";
import EditSlider from "./pages/cms/EditSlider";

import LayoutAfterLog from "./layout/layoutAfterLog";

import { NotificationManager } from "react-notifications";

import {
  userColumns,
  eventColumns,
  containerColumns,
  organisersColumns,
  promoColumns,
} from "./datatablesource";

import {
  eventInputs,
  containerInputs,
  organizationInputs,
  promoInputs,
} from "./formSource";

import DialogPop from "./components/dialog/dialog";

function App() {
  const { darkMode } = useContext(DarkModeContext);
  const history = createBrowserHistory();

  const SupAdminRoute = ({ children }) => {
    const auth = useAuthUser();
    if (!["GM", "CA"].includes(auth().role)) {
      NotificationManager.error(
        "Vous n'avez pas l'autorisation pour voir cette page. Veuillez vous rapprocher du GM  ",
        "Oups !",
        3000,
      );
      history.back();
      return null;
    }

    return children;
  };

  const AdminRoute = ({ children }) => {
    const auth = useAuthUser();
    if (!["GM", "CA", "RMC", "EM"].includes(auth().role)) {
      NotificationManager.error(
        "Vous n'avez pas l'autorisation pour voir cette page. Veuillez vous rapprocher du GM  ",
        "Oups !",
        3000,
      );
      history.back();
      return null;
    }

    return children;
  };

  const AdminRoute2 = ({ children }) => {
    const auth = useAuthUser();

    if (!["GM", "CA", "EM"].includes(auth().role)) {
      NotificationManager.error(
        "Vous n'avez pas l'autorisation pour voir cette page. Veuillez vous rapprocher du GM  ",
        "Oups !",
        3000,
      );
      history.back();
      return null;
    }

    return children;
  };

  const CreateRoute = ({ children }) => {
    const auth = useAuthUser();

    if (!["GM", "CA", "EM", "RMC"].includes(auth().role)) {
      NotificationManager.error(
        "Vous n'avez pas l'autorisation pour voir cette page. Veuillez vous rapprocher du GM  ",
        "Oups !",
        3000,
      );
      history.back();
      return null;
    }

    return children;
  };

  const ViewEventRoute = ({ children }) => {
    const auth = useAuthUser();

    if (!["GM", "CA", "EM", "RMC", "OG"].includes(auth().role)) {
      NotificationManager.error(
        "Vous n'avez pas l'autorisation pour voir cette page. Veuillez vous rapprocher du GM  ",
        "Oups !",
        3000,
      );
      history.back();
      return null;
    }

    return children;
  };

  return (
    <div className={darkMode ? "app dark" : "app"}>
      <BrowserRouter>
        <Routes>
          {/* auth */}
          <Route path="login" element={<Login />} />
          <Route path="ForgotPassword" element={<ForgetMdp />} />
          <Route path="/reset_password/:id" element={<ResetMdp />} />

          <Route
            path="/account-settings"
            element={
              <RequireAuth loginPath={"/login"}>
                <LayoutAfterLog>
                  <AccountSettings />
                </LayoutAfterLog>
              </RequireAuth>
            }
          />

          {/* admin */}
          <Route
            path="/"
            element={
              <RequireAuth loginPath={"/login"}>
                <SupAdminRoute>
                  <LayoutAfterLog>
                    <Home />
                  </LayoutAfterLog>
                </SupAdminRoute>
              </RequireAuth>
            }
          />

          <Route
            path="/admin"
            element={
              <RequireAuth loginPath={"/login"}>
                <SupAdminRoute>
                  <LayoutAfterLog>
                    <List columns={userColumns} screen={"admin"} />
                  </LayoutAfterLog>
                </SupAdminRoute>
              </RequireAuth>
            }
          />

          {/* <Route
            path="/admin/:id"
            element={
              <RequireAuth loginPath={"/login"}>
                <AdminRoute>
                  <EditAdmin inputs={userInputs} title="Editer un admin" />
                </AdminRoute>
              </RequireAuth>
            }
          />

          <Route
            path="/admin/new"
            element={
              <RequireAuth loginPath={"/login"}>
                <AdminRoute>
                  <NewAdmin
                    inputs={userInputs}
                    title="Créer un administrateur"
                  />
                </AdminRoute>
              </RequireAuth>
            }
          /> */}

          {/* Event */}
          <Route
            path="/events"
            element={
              <RequireAuth loginPath={"/login"}>
                <ViewEventRoute>
                  <LayoutAfterLog>
                    <List columns={eventColumns} screen={"event"} />
                  </LayoutAfterLog>
                </ViewEventRoute>
              </RequireAuth>
            }
          />

          <Route
            path="/events/new"
            element={
              <RequireAuth loginPath={"/login"}>
                <CreateRoute>
                  <LayoutAfterLog>
                    <NewEvent
                      inputs={eventInputs}
                      title="Ajouter un Evénement"
                    />
                  </LayoutAfterLog>
                </CreateRoute>
              </RequireAuth>
            }
          />

          <Route
            path="/events/:id"
            element={
              <RequireAuth loginPath={"/login"}>
                <CreateRoute>
                  <LayoutAfterLog>
                    <EditEvent inputs={eventInputs} title="Editer un Event" />
                  </LayoutAfterLog>
                </CreateRoute>
              </RequireAuth>
            }
          />

          <Route
            path="/events/view/:id"
            element={
              <RequireAuth loginPath={"/login"}>
                <ViewEventRoute>
                  <LayoutAfterLog>
                    <ViewEvent inputs={eventInputs} title="Editer un Event" />
                  </LayoutAfterLog>
                </ViewEventRoute>
              </RequireAuth>
            }
          />

          {/* Containeurs */}
          <Route
            path="/containers"
            element={
              <RequireAuth loginPath={"/login"}>
                <SupAdminRoute>
                  <LayoutAfterLog>
                    <List2 columns={containerColumns} />
                  </LayoutAfterLog>
                </SupAdminRoute>
              </RequireAuth>
            }
          />
          <Route
            path="/containers/:id"
            element={
              <RequireAuth loginPath={"/login"}>
                <SupAdminRoute>
                  <LayoutAfterLog>
                    <EditContainer
                      inputs={containerInputs}
                      title="Editer un container"
                    />
                  </LayoutAfterLog>
                </SupAdminRoute>
              </RequireAuth>
            }
          />
          <Route
            path="/containers/new"
            element={
              <RequireAuth loginPath={"/login"}>
                <SupAdminRoute>
                  <LayoutAfterLog>
                    <NewContainer
                      inputs={containerInputs}
                      title="Ajouter un nouveau container"
                    />
                  </LayoutAfterLog>
                </SupAdminRoute>
              </RequireAuth>
            }
          />

          {/* Organisateurs */}
          <Route
            path="/organizers"
            element={
              <RequireAuth loginPath={"/login"}>
                <AdminRoute>
                  <LayoutAfterLog>
                    <List2 columns={organisersColumns} />
                  </LayoutAfterLog>
                </AdminRoute>
              </RequireAuth>
            }
          />
          <Route
            path="/organizers/new"
            element={
              <RequireAuth loginPath={"/login"}>
                <AdminRoute>
                  <LayoutAfterLog>
                    <NewOrganizer
                      inputs={organizationInputs}
                      title="Ajouter un organisateur"
                    />
                  </LayoutAfterLog>
                </AdminRoute>
              </RequireAuth>
            }
          />
          <Route
            path="/organizers/:id"
            element={
              <RequireAuth loginPath={"/login"}>
                <AdminRoute>
                  <LayoutAfterLog>
                    <EditOrganizer
                      inputs={organizationInputs}
                      title="Editer un organisateur"
                    />
                  </LayoutAfterLog>
                </AdminRoute>
              </RequireAuth>
            }
          />

          {/* Promo */}
          <Route
            path="/promo"
            element={
              <RequireAuth loginPath={"/login"}>
                <AdminRoute>
                  <LayoutAfterLog>
                    <List2 columns={promoColumns} />
                  </LayoutAfterLog>
                </AdminRoute>
              </RequireAuth>
            }
          />
          <Route
            path="/promo/:id"
            element={
              <RequireAuth loginPath={"/login"}>
                <AdminRoute>
                  <LayoutAfterLog>
                    <EditPromo
                      inputs={promoInputs}
                      title="Editer le code promo"
                    />
                  </LayoutAfterLog>
                </AdminRoute>
              </RequireAuth>
            }
          />
          <Route
            path="/promo/new"
            element={
              <RequireAuth loginPath={"/login"}>
                <AdminRoute2>
                  <LayoutAfterLog>
                    <NewPromo
                      inputs={promoInputs}
                      title="Ajouter des codes promo"
                    />
                  </LayoutAfterLog>
                </AdminRoute2>
              </RequireAuth>
            }
          />

          {/* Cms */}
          <Route
            path="/slides"
            element={
              <RequireAuth loginPath={"/login"}>
                <AdminRoute>
                  <LayoutAfterLog>
                    <SliderList />
                  </LayoutAfterLog>
                </AdminRoute>
              </RequireAuth>
            }
          />

          <Route
            path="/slider/new"
            element={
              <RequireAuth loginPath={"/login"}>
                <AdminRoute>
                  <LayoutAfterLog>
                    <NewSlider />
                  </LayoutAfterLog>
                </AdminRoute>
              </RequireAuth>
            }
          />

          <Route
            path="/slider/:id"
            element={
              <RequireAuth loginPath={"/login"}>
                <AdminRoute>
                  <LayoutAfterLog>
                    <EditSlider />
                  </LayoutAfterLog>
                </AdminRoute>
              </RequireAuth>
            }
          />

          <Route
            path="/links"
            element={
              <RequireAuth loginPath={"/login"}>
                <AdminRoute>
                  <LayoutAfterLog>
                    <Links />
                  </LayoutAfterLog>
                </AdminRoute>
              </RequireAuth>
            }
          />

          <Route
            path="/jobs"
            element={
              <RequireAuth loginPath={"/login"}>
                <AdminRoute>
                  <LayoutAfterLog>
                    <Job />
                  </LayoutAfterLog>
                </AdminRoute>
              </RequireAuth>
            }
          />

          <Route
            path="/partenaire"
            element={
              <RequireAuth loginPath={"/login"}>
                <AdminRoute>
                  <LayoutAfterLog>
                    <Partenaire />
                  </LayoutAfterLog>
                </AdminRoute>
              </RequireAuth>
            }
          />

          <Route
            path="/talkaboutus"
            element={
              <RequireAuth loginPath={"/login"}>
                <AdminRoute>
                  <LayoutAfterLog>
                    <TalkAboutUs />
                  </LayoutAfterLog>
                </AdminRoute>
              </RequireAuth>
            }
          />

          <Route
            path="/faq"
            element={
              <RequireAuth loginPath={"/login"}>
                <AdminRoute>
                  <LayoutAfterLog>
                    <Faq />
                  </LayoutAfterLog>
                </AdminRoute>
              </RequireAuth>
            }
          />

          <Route
            path="/cgu"
            element={
              <RequireAuth loginPath={"/login"}>
                <AdminRoute>
                  <LayoutAfterLog>
                    <Cgu />
                  </LayoutAfterLog>
                </AdminRoute>
              </RequireAuth>
            }
          />

          <Route
            path="/cgv"
            element={
              <RequireAuth loginPath={"/login"}>
                <AdminRoute>
                  <LayoutAfterLog>
                    <Cgv />
                  </LayoutAfterLog>
                </AdminRoute>
              </RequireAuth>
            }
          />
          <Route
            path="/rgpd"
            element={
              <RequireAuth loginPath={"/login"}>
                <AdminRoute>
                  <LayoutAfterLog>
                    <Rgpd />
                  </LayoutAfterLog>
                </AdminRoute>
              </RequireAuth>
            }
          />
          <Route
            path="/mention"
            element={
              <RequireAuth loginPath={"/login"}>
                <AdminRoute>
                  <LayoutAfterLog>
                    <MentionLegal />
                  </LayoutAfterLog>
                </AdminRoute>
              </RequireAuth>
            }
          />
          <Route
            path="/edit"
            element={
              <RequireAuth loginPath={"/login"}>
                <AdminRoute>
                  <LayoutAfterLog>
                    <Edit />
                  </LayoutAfterLog>
                </AdminRoute>
              </RequireAuth>
            }
          />

          {/* others */}
          <Route exact path="/notFound" element={<NotFound />} />
          <Route path="*" element={<Navigate to="/notFound" />} />
        </Routes>

        <DialogPop />
      </BrowserRouter>
    </div>
  );
}

export default App;
