const BreadCrumbs = (props) => {
  // ** Props
  const { title } = props;

  return (
    <div className="content-header row">
      <div className="content-header-left col-md-9 col-12 mb-2">
        <div className="row breadcrumbs-top">
          <div className="col-12">
            {title ? (
              <h2 className="content-header-title float-start mb-0">{title}</h2>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default BreadCrumbs;
