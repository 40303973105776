import produce from "immer";

const paramsappState = {
  drawer: {
    active: false,
    view: null,
    value: null,
  },

  dialog: {
    active: false,
    view: null,
    value: null,
  },
};

function paramsappReducer(state = paramsappState, action) {
  let nextState;

  switch (action.type) {
    case "setDialog":
      nextState = produce(state, (draftState) => {
        draftState.dialog = action.payload;
      });

      return nextState || state;

    case "setDrawer":
      nextState = produce(state, (draftState) => {
        draftState.drawer = action.payload;
      });

      return nextState || state;

    default:
      return state;
  }
}

export default paramsappReducer;
