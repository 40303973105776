// ** React Imports
import classnames from "classnames";

const BlankLayout = ({ children }) => {
  return (
    <div className={classnames("blank-page")}>
      <div className="app-content content">
        <div className="content-wrapper">
          <div className="content-body">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default BlankLayout;
