// import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  CardTitle,
  Form,
  Label,
  Input,
  Button,
  CardText,
  FormFeedback,
} from "reactstrap";
import { ChevronLeft } from "react-feather";
// import { AuthContext } from "../../context/AuthContext";
import BlankLayout from "../../@core/layouts/BlankLayout";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { AuthApi } from "../../api/authApi";
import source from "../../assets/images/illustration/auth-register-illustration-light.png";
import { NotificationManager } from "react-notifications";
import InputPasswordToggle from "../../@core/components/input-password-toggle";

const schema = yup
  .object({
    newpassword: yup
      .string()
      .required("le mot de passe est requis")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d$@$!%*#?&()+-_]{8,}$/,
        "Minimum huit caractères, au moins une lettre majuscule, une lettre minuscule et un chiffre",
      ),
    confpassword: yup
      .string()
      .required("le mot de passe est requis")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d$@$!%*#?&()+-_]{8,}$/,
        "Minimum huit caractères, au moins une lettre majuscule, une lettre minuscule et un chiffre",
      )
      .when(["newpassword"], (newpassword, schema) => {
        return schema.test(
          "passequal",
          "Entrez un mot de passe identique",
          (value) => {
            if (newpassword === value) {
              return true;
            } else {
              return false;
            }
          },
        );
      }),
  })
  .required();

const ResetMdp = () => {
  const { id } = useParams();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState(false);
  const [veriToken, setVerifToken] = useState(true);

  const navigate = useNavigate();

  const checkToken = async () => {
    const res = await AuthApi.checkpsw({ token: id });
    if (res.success) {
      setVerifToken(false);
    } else {
      window.location.href = `/notFound`;
    }
  };

  useEffect(() => {
    checkToken();
  }, []);

  const handleClick = async (data) => {
    setLoading(true);

    const res = await AuthApi.resetpsw({
      password: data.confpassword,
      resetPasswordToken: id,
    });

    if (res.success) {
      NotificationManager.success(
        "Mot de passe mis à jour avec succès; Vous pouvez vous connecter à présent",
        "",
        8000,
      );
      navigate("../");
    } else {
      NotificationManager.error(
        "Vérifier votre connexion puis Réessayer",
        "",
        3000,
      );
    }

    setLoading(false);
  };

  if (veriToken) {
    return (
      <div className="tw-w-full tw-h-screen tw-flex tw-justify-center tw-items-center">
        Loading...
      </div>
    );
  }

  return (
    <BlankLayout>
      <div className="auth-wrapper auth-cover">
        <Row className="auth-inner m-0">
          {/* <Link className='brand-logo' to='/' onClick={e => e.preventDefault()}>
                </Link> */}

          <Col
            className="d-none d-lg-flex align-items-center p-5"
            lg="8"
            sm="12"
          >
            <div className="w-100 d-lg-flex align-items-center justify-content-center px-5">
              <img
                className="tw-w-[50%] tw-h-[80%] ]"
                src={source}
                alt="Login Cover"
              />
            </div>
          </Col>

          <Col
            className="d-flex align-items-center auth-bg px-2 p-lg-5"
            lg="4"
            sm="12"
          >
            <Col className="px-xl-2 mx-auto" sm="8" md="6" lg="12">
              <CardTitle tag="h2" className="fw-bold mb-1">
                Changer le mot passe 🔒
              </CardTitle>

              <Form
                className="auth-forgot-password-form mt-2"
                onSubmit={handleSubmit(handleClick)}
              >
                <Controller
                  control={control}
                  name="newpassword"
                  render={({ field: { ref, ...fieldProps } }) => (
                    <div className="mb-1">
                      <div className="d-flex justify-content-between">
                        <Label className="form-label" for="login-password">
                          Nouveau mot de passe
                        </Label>
                      </div>
                      <InputPasswordToggle
                        innerRef={ref}
                        fieldProps={fieldProps}
                        invalid={errors?.newpassword}
                        className="input-group-merge"
                        id="login-password1"
                      />
                      {errors?.newpassword && (
                        <FormFeedback>
                          {errors.newpassword?.message}
                        </FormFeedback>
                      )}
                    </div>
                  )}
                />

                <Controller
                  control={control}
                  name="confpassword"
                  render={({ field: { ref, ...fieldProps } }) => (
                    <div className="mb-1">
                      <div className="d-flex justify-content-between">
                        <Label className="form-label" for="login-password">
                          Confirmer Mot de passe
                        </Label>
                      </div>
                      <InputPasswordToggle
                        innerRef={ref}
                        fieldProps={fieldProps}
                        invalid={errors?.confpassword}
                        className="input-group-merge"
                        id="login-password"
                      />
                      {errors?.confpassword && (
                        <FormFeedback>
                          {errors.confpassword?.message}
                        </FormFeedback>
                      )}
                    </div>
                  )}
                />

                <Button disabled={loading} color="primary" block>
                  {loading ? "Chargement..." : "Modifier le mot de passe"}
                </Button>
              </Form>
            </Col>
          </Col>
        </Row>
      </div>
    </BlankLayout>
  );
};

export default ResetMdp;
