import { useState, useEffect } from "react";
import { NotificationManager } from "react-notifications";
import Select from "react-select";
import CircularProgress from "@mui/material/CircularProgress";

import useFetch from "../../hooks/useFetch";
import { Label } from "reactstrap";
import { useLocation, useParams } from "react-router-dom";

import axios from "axios";
import { useAuthUser } from "react-auth-kit";

const EditOrganizer = ({ inputs, title }) => {
  const auth = useAuthUser();
  let { id } = useParams();
  const location = useLocation();
  const path = location.pathname.split("/")[1];
  const { data, loading, error } = useFetch(`/${path}/${id}`, auth().token);

  const [info, setInfo] = useState({});
  const [errors, setErrors] = useState({});
  const [load, setload] = useState(false);

  const containersData = useFetch("/containers") || {};
  const containers = containersData.data.map((container) => ({
    value: container.id,
    label: container.name,
  }));

  useEffect(() => {
    setInfo(data);
  }, [data]);

  const handleChange = (e) => {
    setInfo((prev) => ({ ...prev, [e.target.id]: e.target.value }));
    const { id, value } = e.target;
    switch (id) {
      case "email":
        setErrors((prev) => ({
          ...prev,
          email: value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
            ? ""
            : "Email invalide",
        }));
        break;
      default:
        break;
    }
  };

  const handleClick = async (e) => {
    e.preventDefault();

    setload(true);

    // checke if all information is provide?
    if (!info.container_id) {
      setload(false);
      NotificationManager.error(
        "Veuillez selectionner un container",
        "Error",
        5000,
      );
      return;
    }
    if (
      !info.address ||
      !info.denomination ||
      !info.email ||
      !info.phone ||
      !info.fullname ||
      !info.loyal_form ||
      !info.city ||
      !info.country ||
      !info.contact1_fullname ||
      !info.siret ||
      !info.zip
    ) {
      setload(false);
      NotificationManager.error(
        "Veuillez renseigner tous les champs requis",
        "Error",
        5000,
      );
      return;
    }
    // checke if all information is valid?

    try {
      await axios.put(
        `${process.env.REACT_APP_PUBLIC_URL}/organizers/${id}`,
        info,
        {
          headers: {
            Authorization: `Bearer ${auth().token}`,
          },
        },
      );
      setload(false);
    } catch (err) {
      setload(false);
      return NotificationManager.error(
        "Nous avons rencontré une erreur du serveur",
        "Error",
        5000,
      );
    }
    NotificationManager.success(
      "Organisateur modifié avec success",
      "Success",
      5000,
    );
  };

  if (loading) {
    return (
      <div className="w-100 mt-4 tw-mb-10 d-flex flex-column justify-content-center align-items-center ">
        <CircularProgress sx={{ color: "#E7075B" }} />
      </div>
    );
  }

  return (
    <div className="new">
      <div className="newContainer">
        <div className="top">
          <h1>Modifier l'organisateur</h1>
        </div>
        <div className="bottom">
          <form>
            <Label className="tw-mt-3 tw-text-xs">Adresse *</Label>
            <input
              className="tw-h-[42px] tw-text-[13px]  tw-border tw-w-full tw-px-3 tw-text-gray-700 tw-leading-tight focus:tw-outline-none focus:tw-shadow-outline"
              onChange={handleChange}
              type="text"
              placeholder="Adresse"
              id="address"
              value={info["address"] ? `${info["address"]}` : ""}
            />

            <Label className="tw-mt-3 tw-text-xs">Pays *</Label>
            <input
              className="tw-h-[42px] tw-text-[13px]  tw-border tw-w-full tw-px-3 tw-text-gray-700 tw-leading-tight focus:tw-outline-none focus:tw-shadow-outline"
              onChange={handleChange}
              type="text"
              placeholder="Pays"
              id="country"
              value={info["country"] ? `${info["country"]}` : ""}
            />

            <Label className="tw-mt-3 tw-text-xs">Ville *</Label>
            <input
              className="tw-h-[42px] tw-text-[13px]  tw-border tw-w-full tw-px-3 tw-text-gray-700 tw-leading-tight focus:tw-outline-none focus:tw-shadow-outline"
              onChange={handleChange}
              type="text"
              placeholder="Ville"
              id="city"
              value={info["city"] ? `${info["city"]}` : ""}
            />
            <Label className="tw-mt-3 tw-text-xs">Forme de Juridique *</Label>
            <input
              className="tw-h-[42px] tw-text-[13px]  tw-border tw-w-full tw-px-3 tw-text-gray-700 tw-leading-tight focus:tw-outline-none focus:tw-shadow-outline"
              onChange={handleChange}
              type="text"
              placeholder="Forme de Juridique"
              id="loyal_form"
              value={info["loyal_form"] ? `${info["loyal_form"]}` : ""}
            />

            <Label className="tw-mt-3 tw-text-xs">Nom et prénoms * </Label>
            <input
              className="tw-h-[42px] tw-text-[13px]  tw-border tw-w-full tw-px-3 tw-text-gray-700 tw-leading-tight focus:tw-outline-none focus:tw-shadow-outline"
              onChange={handleChange}
              type="text"
              placeholder="Nom et prénom"
              id="fullname"
              value={info["fullname"] ? `${info["fullname"]}` : ""}
            />

            <Label className="tw-mt-3 tw-text-xs">Dénomination *</Label>
            <input
              className="tw-h-[42px] tw-text-[13px]  tw-border tw-w-full tw-px-3 tw-text-gray-700 tw-leading-tight focus:tw-outline-none focus:tw-shadow-outline"
              onChange={handleChange}
              type="text"
              placeholder="Dénomination"
              id="denomination"
              value={info["denomination"] ? `${info["denomination"]}` : ""}
            />

            <Label className="tw-mt-3 tw-text-xs">SIRET *</Label>
            <input
              className="tw-h-[42px] tw-text-[13px]  tw-border tw-w-full tw-px-3 tw-text-gray-700 tw-leading-tight focus:tw-outline-none focus:tw-shadow-outline"
              onChange={handleChange}
              type="text"
              placeholder="SIRET"
              id="siret"
              value={info["siret"] ? `${info["siret"]}` : ""}
            />

            <Label className="tw-mt-3 tw-text-xs">ZIP *</Label>
            <input
              className="tw-h-[42px] tw-text-[13px]  tw-border tw-w-full tw-px-3 tw-text-gray-700 tw-leading-tight focus:tw-outline-none focus:tw-shadow-outline"
              onChange={handleChange}
              type="text"
              placeholder="Code postal"
              id="zip"
              value={info["zip"] ? `${info["zip"]}` : ""}
            />

            <Label className="tw-mt-3 tw-text-xs">
              Attribuer un Conteneur *
            </Label>
            <Select
              options={containers}
              onChange={(e) =>
                setInfo((prev) => ({ ...prev, container_id: e.value }))
              }
              value={
                info["container_id"] &&
                containers.find((e) => e.value === info["container_id"])
              }
            />

            <div className="w-100 tw-my-8 tw-ring-1 tw-py-3 tw-px-3 tw-rounded-md tw-ring-slate-400 position-relative tw-flex tw-items-right tw-flex-col">
              <Label className="tw-mt-3 tw-text-xs">Nom et prénoms *</Label>
              <input
                className="tw-h-[42px] tw-text-[13px]  tw-border tw-w-full tw-px-3 tw-text-gray-700 tw-leading-tight focus:tw-outline-none focus:tw-shadow-outline"
                onChange={handleChange}
                type="text"
                placeholder="Nom et prénoms"
                id="contact1_fullname"
                value={
                  info["contact1_fullname"]
                    ? `${info["contact1_fullname"]}`
                    : ""
                }
              />

              <Label className="tw-mt-3 tw-text-xs">Téléphone 1 *</Label>
              <input
                className="tw-h-[42px] tw-text-[13px]  tw-border tw-w-full tw-px-3 tw-text-gray-700 tw-leading-tight focus:tw-outline-none focus:tw-shadow-outline"
                onChange={handleChange}
                type="text"
                placeholder="+1 234 567 89"
                id="phone"
                value={info["phone"] ? `${info["phone"]}` : ""}
              />
              <Label className="tw-mt-3 tw-text-xs">Email 1 *</Label>
              <input
                className="tw-h-[42px] tw-text-[13px]  tw-border tw-w-full tw-px-3 tw-text-gray-700 tw-leading-tight focus:tw-outline-none focus:tw-shadow-outline"
                onChange={handleChange}
                type="text"
                placeholder="Email"
                id="email"
                value={info["email"] ? `${info["email"]}` : ""}
              />
              <div
                className=" position-absolute px-3 d-flex"
                style={{
                  top: -15,
                  left: 50,
                  height: 30,
                  backgroundColor: "#F8F8F8",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span className="title-forfait">Contact 1</span>
              </div>
            </div>

            <div className="w-100 tw-my-8 tw-ring-1 tw-py-3 tw-px-3 tw-rounded-md tw-ring-slate-400 position-relative tw-flex tw-items-right tw-flex-col">
              <Label className="tw-mt-3 tw-text-xs">Nom et prénoms</Label>
              <input
                className="tw-h-[42px] tw-text-[13px]  tw-border tw-w-full tw-px-3 tw-text-gray-700 tw-leading-tight focus:tw-outline-none focus:tw-shadow-outline"
                onChange={handleChange}
                type="text"
                placeholder="Nom et prénoms"
                id="contact2_fullname"
                value={
                  info["contact2_fullname"]
                    ? `${info["contact2_fullname"]}`
                    : ""
                }
              />

              <Label className="tw-mt-3 tw-text-xs">Téléphone 2</Label>
              <input
                className="tw-h-[42px] tw-text-[13px]  tw-border tw-w-full tw-px-3 tw-text-gray-700 tw-leading-tight focus:tw-outline-none focus:tw-shadow-outline"
                onChange={handleChange}
                type="text"
                placeholder="+1 234 567 89"
                id="phone2"
                value={info["phone2"] ? `${info["phone2"]}` : ""}
              />
              <Label className="tw-mt-3 tw-text-xs">Email 2</Label>
              <input
                className="tw-h-[42px] tw-text-[13px]  tw-border tw-w-full tw-px-3 tw-text-gray-700 tw-leading-tight focus:tw-outline-none focus:tw-shadow-outline"
                onChange={handleChange}
                type="text"
                placeholder="Email"
                id="email2"
                value={info["email2"] ? `${info["email2"]}` : ""}
              />
              <div
                className=" position-absolute px-3 d-flex"
                style={{
                  top: -15,
                  left: 50,
                  height: 30,
                  backgroundColor: "#F8F8F8",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span className="title-forfait">Contact 2</span>
              </div>
            </div>

            <div className="w-100 tw-my-8 tw-ring-1 tw-py-3 tw-px-3 tw-rounded-md tw-ring-slate-400 position-relative tw-flex tw-items-right tw-flex-col">
              <Label className="tw-mt-3 tw-text-xs">Nom et prénoms</Label>
              <input
                className="tw-h-[42px] tw-text-[13px]  tw-border tw-w-full tw-px-3 tw-text-gray-700 tw-leading-tight focus:tw-outline-none focus:tw-shadow-outline"
                onChange={handleChange}
                type="text"
                placeholder="Nom et prénoms"
                id="compta_fullname"
                value={
                  info["compta_fullname"] ? `${info["compta_fullname"]}` : ""
                }
              />

              <Label className="tw-mt-3 tw-text-xs">
                Téléphone comptabilité
              </Label>
              <input
                className="tw-h-[42px] tw-text-[13px]  tw-border tw-w-full tw-px-3 tw-text-gray-700 tw-leading-tight focus:tw-outline-none focus:tw-shadow-outline"
                onChange={handleChange}
                type="text"
                placeholder="+1 234 567 89"
                id="accounter_contact"
                value={
                  info["accounter_contact"]
                    ? `${info["accounter_contact"]}`
                    : ""
                }
              />
              <Label className="tw-mt-3 tw-text-xs">Email comptabilité</Label>
              <input
                className="tw-h-[42px] tw-text-[13px]  tw-border tw-w-full tw-px-3 tw-text-gray-700 tw-leading-tight focus:tw-outline-none focus:tw-shadow-outline"
                onChange={handleChange}
                type="text"
                placeholder="Email"
                id="accounter_email"
                value={
                  info["accounter_email"] ? `${info["accounter_email"]}` : ""
                }
              />
              <div
                className=" position-absolute px-3 d-flex"
                style={{
                  top: -15,
                  left: 50,
                  height: 30,
                  backgroundColor: "#F8F8F8",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span className="title-forfait">Contact comptabilité</span>
              </div>
            </div>

            {/* <Label className="tw-mt-3 tw-text-xs" >Montant total (en €)</Label>
                        <input
                            className="tw-h-[42px] tw-text-[13px]  tw-border tw-w-full tw-px-3 tw-text-gray-700 tw-leading-tight focus:tw-outline-none focus:tw-shadow-outline"
                            onChange={handleChange}
                            type="number"
                            placeholder="Montant total"
                            id="total_amont"
                            value={info["total_amont"] ? `${info["total_amont"]}` : ""}
                        /> */}

            <br />
            <br />
            <button
              disabled={load}
              type="submit"
              className="tw-text-sm tw-font-semibold tw-h-[35px] tw-px-3 tw-rounded-md tw-text-white btn-fushia"
              onClick={handleClick}
            >
              {load ? "Chargement ..." : "Modifier l'organisateur"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditOrganizer;
