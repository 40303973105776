import { useState, useEffect } from "react";
import { NotificationManager } from "react-notifications";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import useFetch from "../../hooks/useFetch";
import moment from "moment";
import axios from "axios";
import { ConstructionOutlined } from "@mui/icons-material";
import { useLocation, useParams } from "react-router-dom";

const EditPromo = ({ inputs, title }) => {
  let { id } = useParams();
  const location = useLocation();
  const path = location.pathname.split("/")[1];
  const { data } = useFetch(`/${path}/${id}`);

  const [info, setInfo] = useState({});
  const [errors, setErrors] = useState({});

  const eventData = useFetch("/events") || {};
  const events = eventData.data.map((event) => ({
    value: event.id,
    label: event.event,
  }));

  useEffect(() => {
    setInfo(data);
  }, [data]);

  const handleChange = (e) => {
    setInfo((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const handleClick = async (e) => {
    e.preventDefault();
    // checke if all information is provide?
    if (!info.event_id) {
      NotificationManager.error(
        "Veuillez selectionner un event",
        "Error",
        5000,
      );
      return;
    }
    if (!info.generatedTime || !info.start_date || !info.end_date) {
      NotificationManager.error(
        "Veuillez renseigner tous les champs requis",
        "Error",
        5000,
      );
      return;
    }

    setInfo((prev) => ({
      ...prev,
      start_date: moment(info.start_date).format("l"),
      end_date: new Date(info.end_date).format("l"),
    }));

    try {
      await axios.post(`${process.env.REACT_APP_PUBLIC_URL}/promo`, info);
    } catch (err) {
      NotificationManager.error(
        "Nous avons rencontré une erreur du serveur",
        "Error",
        5000,
      );
    }
    NotificationManager.success(
      "Codes promo créés avec success",
      "Success",
      5000,
    );
    setInfo({});
    // reset form input to blanc?
    document.querySelectorAll("input").forEach((input) => (input.value = ""));
    document
      .querySelectorAll(".css-qc6sy-singleValue")
      .forEach((div) => (div.innerHTML = "Select..."));
  };

  return (
    <div className="new">
      {/* <Sidebar /> */}
      <div className="newContainer">
        {/* <Navbar /> */}
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          <div className="right">
            <form>
              <div className="formInput">
                <label>Choisir un event</label>
                <Select
                  options={events}
                  onChange={(e) =>
                    setInfo((prev) => ({ ...prev, event_id: e.value }))
                  }
                />
              </div>
              <div className="formInput">
                <label>Date de début</label>
                <DatePicker
                  selected={info.start_date}
                  placeholderText="dd/mm/yyyy"
                  onChange={(date) =>
                    setInfo((prev) => ({
                      ...prev,
                      start_date: date,
                    }))
                  }
                  minDate={new Date()}
                />
                <br />
                <label>Date de fin</label>
                <DatePicker
                  selected={info.end_date}
                  placeholderText="dd/mm/yyyy"
                  onChange={(date) =>
                    setInfo((prev) => ({
                      ...prev,
                      end_date: date,
                    }))
                  }
                  minDate={info.start_date || new Date()}
                />
              </div>
              {inputs.map((input) => (
                <div className="formInput" key={input.id}>
                  <label>{input.label}</label>
                  <input
                    onChange={handleChange}
                    type={input.type}
                    placeholder={input.placeholder}
                    id={input.id}
                  />
                  <span className="error text-danger">{errors[input.id]}</span>
                </div>
              ))}
              <button onClick={handleClick} className="btn-submit">
                Envoyer
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPromo;
