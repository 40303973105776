// ** Reactstrap Imports
import { Fragment, useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardImgOverlay,
  CardText,
  CardImg,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  AccordionBody,
  AccordionItem,
  AccordionHeader,
  UncontrolledAccordion,
  Row,
  Col,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import {
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  FormFeedback,
  UncontrolledTooltip,
} from "reactstrap";

import { CMSApi } from "../../api/cmsApi";
import { useDispatch, useSelector } from "react-redux";
import produce from "immer";
import Breadcrumbs from "../../@core/components/breadcrumbs";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { NotificationManager } from "react-notifications";

import "./cms.scss";

const TalkAboutUs = () => {
  const schema = yup
    .object({
      name: yup
        .string()
        .required("Entrez un titre")
        .min(3, "Le titre doit contenir au moins 3 caractères"),
      body: yup
        .string()
        .required("Entrez une desciption")
        .min(3, "la description doit contenir au moins 3 caractères"),
      link: yup.string().required("Entrez un lien ").url("Url invalide"),
      // image: yup.mixed().test("file", "Choississez une image", (value) => {
      //     if (value?.length > 0) {
      //       return true;
      //     }
      //     return false;
      // })
      // .test("fileSize", "Choississez une image inférieur à 100kb", (value) => {
      //     if (value?.["0"].size > 100000) {
      //       return false;
      //     }
      //     return true;
      // })
    })
    .required();

  const {
    reset,
    control,
    handleSubmit,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);

  const [testimonie, settestimonie] = useState([]);
  const [show, setShow] = useState(false);
  const [modalType, setModalType] = useState("Add New");

  const getAllTestimonies = async (val) => {
    setLoading(true);
    const res = await CMSApi.getAllTesti();
    setLoading(false);

    if (res.success) {
      settestimonie(res.data);
    } else {
      NotificationManager.error(`${res.message}`, "", 3000);
    }
  };

  useEffect(() => {
    getAllTestimonies();
  }, []);

  const handleAddTestimonies = async (data) => {
    setLoad(true);
    const res = await CMSApi.createTesti(data);
    setLoad(false);

    if (res.success) {
      settestimonie([res.data.data, ...testimonie]);
      NotificationManager.success("Temoignage ajouté avec succès ", "", 3000);
    } else {
      NotificationManager.error(`${res.message}`, "", 3000);
    }
  };

  const handlePutTestimonies = async (data, index) => {
    let datanew = data;
    delete datanew.created_at;
    delete datanew.id;
    delete datanew.index;

    setLoad(true);
    const res = await CMSApi.putJob(datanew, data.id);
    setLoad(false);

    if (res.success) {
      settestimonie(
        produce((draft) => {
          draft[index] = res.data.data;
        }),
      );
      NotificationManager.success("Temoignage modifier avec succès ", "", 3000);
    } else {
      NotificationManager.error(`${res.message}`, "", 3000);
    }
  };

  const handleDelete = async () => {
    setLoad(true);
    const res = await CMSApi.deleteTesti(modalType.id);
    setLoad(false);

    if (res.success) {
      settestimonie(
        produce((draft) => {
          draft.splice(modalType.index, 1);
        }),
      );
      setShow(false);
      NotificationManager.success(
        "Témoignage supprimer avec succès ",
        "",
        3000,
      );
    } else {
      NotificationManager.error(`${res.message}`, "", 3000);
    }
  };

  const onSubmit = (data) => {
    if (modalType === "Ajouter") {
      handleAddTestimonies(data);
      setShow(false);
    } else if (modalType === "Modifier") {
      if (isDirty) {
        handlePutTestimonies(data, data.index);
        setShow(false);
      } else {
        NotificationManager.error(`Aucun champ modifié`, "", 3000);
      }
    }
  };

  const handleModalClosed = () => {
    setModalType("Add New");
    reset({ name: "", body: "", link: "", image: "" });
  };

  if (loading) {
    return (
      <div className=" tw-w-full tw-h-full tw-flex tw-justify-center tw-items-center">
        Loading...
      </div>
    );
  }

  return (
    <div className="datatable">
      <div className=" tw-w-full tw-my-3">
        <Breadcrumbs title="On Parle de nous" />

        <div className=" tw-w-full tw-h-[40px] tw-flex tw-mb-4 tw-justify-end tw-items-end ">
          <Button
            disabled={load}
            onClick={() => {
              setModalType("Ajouter");
              setShow(true);
            }}
            type="submit"
            className="me-1 btn-fushia "
          >
            Ajouter
          </Button>
        </div>

        <div id="knowledge-base-content">
          <Row className="kb-search-content-info match-height">
            {testimonie.map((item, index) => (
              <Col key={index} className="kb-search-content" md="4" sm="6">
                <Card>
                  <img
                    className=" tw-w-full tw-h-[250px] tw-object-cover"
                    src={
                      item?.image &&
                      "https://mylocker-app-backend.herokuapp.com/api/v1/cms/slide/image/" +
                        item?.image
                    }
                  />

                  <CardBody className="text-start">
                    <h4>{item.name}</h4>
                    <p className="text-body mt-1 mb-0">{item.body}</p>
                  </CardBody>

                  <Col className="text-center tw-mt-1 tw-mb-2" xs={12}>
                    {/* <Button
                                        onClick={e => {
                                            reset({...item, index: index})
                                            setModalType('Modifier')
                                            setShow(true)
                                        }} 
                                        disabled={load} color='primary' className='me-1'
                                    >
                                        {load ? "Load..." : "Modifier"}
                                    </Button> */}

                    <Button
                      onClick={() => {
                        setShow(true);
                        setModalType({ ...item, index: index });
                      }}
                      disabled={load}
                      color="danger"
                      className="me-1"
                    >
                      {load ? "Load..." : "Supprimer"}
                    </Button>
                  </Col>
                </Card>
              </Col>
            ))}
          </Row>
        </div>

        <Modal
          isOpen={show}
          onClosed={handleModalClosed}
          toggle={() => setShow(!show)}
          className="modal-dialog-centered modal-lg"
        >
          <ModalHeader
            className="bg-transparent"
            toggle={() => setShow(!show)}
          ></ModalHeader>

          {typeof modalType !== "object" ? (
            <ModalBody className="px-5 pb-5">
              <div className="text-center mb-4">
                <h1>{modalType} un témoignage</h1>
              </div>
              <Row tag="form" onSubmit={handleSubmit(onSubmit)}>
                <Col xs={12}>
                  <InputGroup>
                    <Controller
                      name="name"
                      control={control}
                      render={({ field }) => (
                        <Input
                          className="tw-mt-3"
                          invalid={errors?.name && true}
                          {...field}
                          placeholder="Titre ..."
                        />
                      )}
                    />
                  </InputGroup>
                  <p className=" tw-text-red-500 tw-text-[11px] ">
                    {errors?.name?.message}
                  </p>

                  <InputGroup>
                    <Controller
                      name="body"
                      control={control}
                      render={({ field }) => (
                        <Input
                          type="textarea"
                          invalid={errors?.body && true}
                          {...field}
                          className="tw-mt-3"
                          placeholder="Description ..."
                        />
                      )}
                    />
                  </InputGroup>
                  <p className=" tw-text-red-500 tw-text-[11px] ">
                    {errors?.body?.message}
                  </p>

                  <InputGroup>
                    <Controller
                      name="link"
                      control={control}
                      render={({ field }) => (
                        <Input
                          className="tw-mt-3"
                          invalid={errors?.link && true}
                          {...field}
                          placeholder="Lien ..."
                        />
                      )}
                    />
                  </InputGroup>
                  <p className=" tw-text-red-500 tw-text-[11px] ">
                    {errors?.link?.message}
                  </p>

                  <InputGroup>
                    <Controller
                      name="image"
                      control={control}
                      render={({ field }) => (
                        <Input
                          onChange={(e) => {
                            field.onChange(e.target.files["0"]);
                          }}
                          className="tw-mt-3"
                          type="file"
                          accept=".jpg,.jpeg,.png"
                          multiple={false}
                          invalid={errors?.image && true}
                        />
                      )}
                    />
                  </InputGroup>
                  <p className=" tw-text-red-500 tw-text-[11px] ">
                    {errors?.image?.message}
                  </p>
                </Col>

                <Col className="text-center mt-2" xs={12}>
                  <Button
                    type="submit"
                    disabled={load}
                    className="me-1 tw-text-sm tw-font-semibold tw-h-[35px] tw-px-3 tw-rounded-md tw-text-white btn-fushia"
                  >
                    {load ? "Load..." : "Ajouter"}
                  </Button>
                </Col>
              </Row>
            </ModalBody>
          ) : (
            <ModalBody className="px-5 pb-5">
              <div className="text-center mb-4">
                <h1>Supprimer le témoignage</h1>
              </div>
              <Row tag="form" onSubmit={handleSubmit(onSubmit)}>
                <Col className="tw-flex tw-justify-center" xs={12}>
                  Voulez vous supprimer ce Témoignage ?
                </Col>

                <Col className="text-center mt-2" xs={12}>
                  <Button
                    type="submit"
                    onClick={() => setShow(false)}
                    disabled={load}
                    className="me-1 tw-text-sm tw-font-semibold tw-h-[35px] tw-px-3 tw-rounded-md tw-text-white btn-fushia"
                  >
                    {load ? "Load..." : "Non"}
                  </Button>

                  <Button
                    type="submit"
                    onClick={() => handleDelete()}
                    disabled={load}
                    color="danger"
                    className="me-1"
                  >
                    {load ? "Load..." : "Oui"}
                  </Button>
                </Col>
              </Row>
            </ModalBody>
          )}
        </Modal>
      </div>
    </div>
  );
};

export default TalkAboutUs;
