import {
  legacy_createStore as createStore,
  applyMiddleware,
  combineReducers,
} from "redux";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";

import createSagaMiddleware from "redux-saga";
import { rootSaga } from "./sagas/indexSaga";
import { persistReducer } from "redux-persist";

import paramsappReducer from "./reducers/paramsReducer";
import adminappReducer from "./reducers/adminappReducer";
import eventappReducer from "./reducers/eventappReducer";
import faqappReducer from "./reducers/faqappReducer";

const configureStore = () => {
  // const rootPersistConfig1 = {
  //   key: 'cookieAuth',
  //   storage: new CookieStorage(Cookies, {
  //     expiration: {
  //       'default': 3600
  //     }
  //   })
  // }

  const rootPersistConfig = {
    key: "storage",
    storage: storage,
  };

  const sagaMiddleware = createSagaMiddleware();

  const middleWares = [sagaMiddleware, thunk];

  const store = createStore(
    combineReducers({
      paramsappReducer: paramsappReducer,
      adminappReducer: persistReducer(rootPersistConfig, adminappReducer),
      eventappReducer: persistReducer(rootPersistConfig, eventappReducer),
      faqappReducer: persistReducer(rootPersistConfig, faqappReducer),
    }),
    applyMiddleware(...middleWares),
  );

  sagaMiddleware.run(rootSaga);

  return store;
};

export default configureStore;
