const baseUrl = process.env.REACT_APP_PUBLIC_URL;

export const AuthApi = {
  async login(logdata) {
    try {
      const response = await fetch(baseUrl + "/auth/admin", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(logdata),
        // credentials: 'include'
      });
      const res = await response.json();

      if (response.ok) {
        return {
          success: true,
          data: res,
        };
      } else {
        return {
          success: false,
          message: res?.message,
        };
      }
    } catch (error) {
      return {
        success: false,
        message: "error",
      };
    }
  },

  async forgotpsw(logdata) {
    try {
      const response = await fetch(baseUrl + "/auth/forgot-password", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(logdata),
        // credentials: 'include'
      });

      const res = await response.json();

      if (response.ok) {
        return {
          success: true,
          data: res,
        };
      } else {
        return {
          success: false,
          message: res?.message,
        };
      }
    } catch (error) {
      return {
        success: false,
        message: "error",
      };
    }
  },

  async checkpsw(logdata) {
    try {
      const response = await fetch(baseUrl + "/auth/check-token", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(logdata),
        // credentials: 'include'
      });

      const res = await response.json();

      if (response.ok) {
        return {
          success: true,
          data: res,
        };
      } else {
        return {
          success: false,
          message: res?.message,
        };
      }
    } catch (error) {
      return {
        success: false,
        message: "error",
      };
    }
  },

  async resetpsw(logdata) {
    try {
      const response = await fetch(baseUrl + "/auth/reset-password", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(logdata),
      });

      const res = await response.json();

      if (response.ok) {
        return {
          success: true,
          data: res,
        };
      } else {
        return {
          success: false,
          message: res?.message,
        };
      }
    } catch (error) {
      return {
        success: false,
        message: "error",
      };
    }
  },

  async putProfil(logdata, id, token) {
    try {
      const response = await fetch(baseUrl + `/admin/${id}`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(logdata),
      });

      const res = await response.json();

      if (response.ok) {
        return {
          success: true,
          data: res,
        };
      } else {
        return {
          success: false,
          message: res?.message,
        };
      }
    } catch (error) {
      return {
        success: false,
        message: "error",
      };
    }
  },
};
