import React, { useState } from "react";
import {
  Row,
  Col,
  Form,
  Card,
  Input,
  Label,
  Button,
  CardBody,
  CardTitle,
  CardHeader,
  FormFeedback,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useAuthUser } from "react-auth-kit";
import { AuthApi } from "../../api/authApi";
import { NotificationManager } from "react-notifications";
import InputPasswordToggle from "../../@core/components/input-password-toggle";

const PasswordSettings = () => {
  const auth = useAuthUser();

  const schema = yup
    .object()
    .shape(
      {
        oldpassword: yup
          .string()
          .required("le mot de passe est requis")
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d$@$!%*#?&()+-_]{8,}$/,
            "Minimum huit caractères, au moins une lettre majuscule, une lettre minuscule et un chiffre",
          ),
        newpassword: yup
          .string()
          .required("le mot de passe est requis")
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d$@$!%*#?&()+-_]{8,}$/,
            "Minimum huit caractères, au moins une lettre majuscule, une lettre minuscule et un chiffre",
          ),
        confpassword: yup
          .string()
          .required("le mot de passe est requis")
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d$@$!%*#?&()+-_]{8,}$/,
            "Minimum huit caractères, au moins une lettre majuscule, une lettre minuscule et un chiffre",
          )
          .when(["newpassword"], (newpassword, schema) => {
            return schema.test(
              "passequal",
              "Entrez un mot de passe identique",
              (value) => {
                if (newpassword === value) {
                  return true;
                } else {
                  return false;
                }
              },
            );
          }),
      },
      [],
    )
    .required();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [load, setLoad] = useState(false);

  const onSubmit = async (data) => {
    setLoad(true);
    const res = await AuthApi.putProfil(
      {
        first_name: auth().first_name,
        last_name: auth().last_name,
        telephone: auth().telephone,
        email: auth().email,
        role: auth().role,
        password: data.newpassword,
      },
      auth().id,
      auth().token,
    );
    setLoad(false);

    if (res.success) {
      NotificationManager.success(
        "Mot de passe modifié avec succès ",
        "",
        3000,
      );
    } else {
      NotificationManager.error(`${res.message}`, "", 3000);
    }
  };

  return (
    <Row>
      <Col xs={12}>
        <Card>
          <CardHeader className="border-bottom">
            <CardTitle tag="h4">Mot de passe</CardTitle>
          </CardHeader>
          <CardBody className="py-2 my-25">
            <Form className="mt-2 pt-50" onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col sm="6" className="mb-1">
                  <Label className="form-label" for="firstName">
                    Ancien Mot de passe
                  </Label>
                  <Controller
                    name="oldpassword"
                    control={control}
                    render={({ field: { ref, ...fieldProps } }) => (
                      <InputPasswordToggle
                        innerRef={ref}
                        fieldProps={fieldProps}
                        invalid={errors?.oldpassword}
                        className="input-group-merge"
                        id="login-password1"
                      />
                    )}
                  />
                  {errors && errors.oldpassword && (
                    <FormFeedback>{errors.oldpassword.message}</FormFeedback>
                  )}
                </Col>

                <Col sm="6" className="mb-1">
                  <Label className="form-label" for="lastName">
                    Nouveau Mot de Passe
                  </Label>
                  <Controller
                    name="newpassword"
                    control={control}
                    render={({ field: { ref, ...fieldProps } }) => (
                      <InputPasswordToggle
                        innerRef={ref}
                        fieldProps={fieldProps}
                        invalid={errors?.newpassword}
                        className="input-group-merge"
                        id="login-password2"
                      />
                    )}
                  />
                  {errors.newpassword && (
                    <FormFeedback>{errors.newpassword.message}</FormFeedback>
                  )}
                </Col>

                <Col sm="6" className="mb-1">
                  <Label className="form-label" for="lastName">
                    Confirmer le Mot de Passe
                  </Label>
                  <Controller
                    name="confpassword"
                    control={control}
                    render={({ field: { ref, ...fieldProps } }) => (
                      <InputPasswordToggle
                        innerRef={ref}
                        fieldProps={fieldProps}
                        invalid={errors?.confpassword}
                        className="input-group-merge"
                        id="login-password3"
                      />
                    )}
                  />
                  {errors.confpassword && (
                    <FormFeedback>{errors.confpassword.message}</FormFeedback>
                  )}
                </Col>
              </Row>

              <Button disabled={load} type="submit" className="me-1 btn-fushia">
                {load ? "Load..." : "Modifier le mot de passe"}
              </Button>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default PasswordSettings;
