import React, { useState, useCallback, useEffect } from "react";
import { useLocation, useParams } from "react-router";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import CircularProgress from "@mui/material/CircularProgress";

import { NotificationManager } from "react-notifications";
import axios from "axios";

import { useForm } from "react-hook-form";
import useFetch from "../../hooks/useFetch";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Label } from "reactstrap";
import { useDropzone } from "react-dropzone";
import { EventApi } from "../../api/eventApi";

// import { FiDownloadCloud } from 'react-icons/fi'
import "./slide.scss";

const EditSlider = () => {
  let { id } = useParams();

  const location = useLocation();
  const slide = location.state.data;

  const formData = new FormData();

  const [load, setload] = useState(false);
  const [image, setImage] = useState(true);
  const [file, setFile] = useState(null);
  const [events, setEvents] = useState([]);

  const schema = yup.object().shape({
    caption: yup.string().required("Ce champs est obligatoire"),
    subtitle: yup.string().required("Ce champs est obligatoire"),
    slot: yup.string().required("Ce champs est obligatoire"),
    altText: yup.string().required("Ce champs est obligatoire"),
    slide_order: yup.string().required("Ce champs est obligatoire"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      caption: slide.caption,
      subtitle: slide?.subtitle,
      slot: slide?.slot,
      altText: slide?.alt_text,
      slide_order: slide?.slide_order,
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    EventApi.getAllEvent().then((res) => {
      setEvents(res?.data);
    });
  }, []);

  const onSubmit = async (data) => {
    setload(true);

    if (file) {
      formData.append("image", file);
    } else {
      formData.append("image", slide?.image);
    }
    formData.append("altText", data.altText);
    formData.append("caption", data.caption);
    formData.append("subtitle", data.subtitle);
    formData.append("slot", data.slot);
    formData.append("slide_order", data.slide_order);
    formData.append(
      "event_id",
      data?.event_id ? parseInt(data.event_id) : parseInt(slide?.event_id),
    );
    try {
      // Envoyer les données du formulaire au serveur ici...
      await axios.put(
        `${process.env.REACT_APP_PUBLIC_URL}/cms/slide/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );

      setload(false);
      return NotificationManager.success("Success", "Success", 5000);
    } catch (err) {
      setload(false);
      return NotificationManager.error(
        "Nous avons rencontré une erreur du serveur",
        "Error",
        5000,
      );
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    // add file in fonrData
    setFile(acceptedFiles[0]);
    setImage(true);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div className="new">
      <div className="newContainer">
        <div className="container tw-mb-3">
          <form>
            <Label className="tw-mt-3 tw-text-xs">Titre</Label>
            <input
              disabled={load}
              {...register("caption")}
              type="text"
              placeholder=""
              className="tw-h-[42px] tw-text-[13px]  tw-border tw-rounded-[50px] tw-bg-[#E8F0FE] tw-w-full tw-px-3 tw-text-gray-700 tw-leading-tight focus:tw-outline-none focus:tw-shadow-outline"
              id="number"
            />
            <p className="tw-text-xs tw-mt-[5px] tw-text-red-600">
              {errors.caption?.message}
            </p>

            <Label className="tw-mt-3 tw-text-xs">Sous titre</Label>
            <input
              disabled={load}
              {...register("subtitle")}
              type="text"
              placeholder=""
              className="tw-h-[42px] tw-text-[13px]  tw-border tw-rounded-[50px] tw-bg-[#E8F0FE] tw-w-full tw-px-3 tw-text-gray-700 tw-leading-tight focus:tw-outline-none focus:tw-shadow-outline"
              id="mail"
            />
            <p className="tw-text-xs tw-mt-[5px] tw-text-red-600">
              {errors.subtitle?.message}
            </p>

            <Label className="tw-mt-3 tw-text-xs">Slogan</Label>
            <input
              disabled={load}
              {...register("slot")}
              type="text"
              placeholder=""
              className="tw-h-[42px] tw-text-[13px]  tw-border tw-rounded-[50px] tw-bg-[#E8F0FE] tw-w-full tw-px-3 tw-text-gray-700 tw-leading-tight focus:tw-outline-none focus:tw-shadow-outline"
              id="phonenumber"
            />
            <p className="tw-text-xs tw-mt-[5px] tw-text-red-600">
              {errors.slot?.message}
            </p>

            <select
              {...register("event_id")}
              className="tw-h-[42px] tw-text-[13px]  tw-border tw-rounded-[50px] tw-bg-[#E8F0FE] tw-w-full tw-px-3 tw-text-gray-700 tw-leading-tight focus:tw-outline-none focus:tw-shadow-outline"
            >
              {events?.map((data) => {
                return <option value={data?.id}>{data?.event}</option>;
              })}
            </select>

            <Label className="tw-mt-3 tw-text-xs">Description</Label>
            <input
              disabled={load}
              {...register("altText")}
              type="text"
              placeholder=""
              className="tw-h-[42px] tw-text-[13px]  tw-border tw-rounded-[50px] tw-bg-[#E8F0FE] tw-w-full tw-px-3 tw-text-gray-700 tw-leading-tight focus:tw-outline-none focus:tw-shadow-outline"
              id="phonenumber"
            />
            <p className="tw-text-xs tw-mt-[5px] tw-text-red-600">
              {errors.altText?.message}
            </p>

            <Label className="tw-mt-3 tw-text-xs">Ordre d'apparition</Label>
            <input
              disabled={load}
              {...register("slide_order")}
              type="text"
              placeholder=""
              className="tw-h-[42px] tw-text-[13px]  tw-border tw-rounded-[50px] tw-bg-[#E8F0FE] tw-w-full tw-px-3 tw-text-gray-700 tw-leading-tight focus:tw-outline-none focus:tw-shadow-outline"
              id="phonenumber"
            />
            <p className="tw-text-xs tw-mt-[5px] tw-text-red-600">
              {errors.slide_order?.message}
            </p>

            <Label className="tw-mt-3 tw-text-xs">Téléverser l'image</Label>

            <div
              {...getRootProps({
                className:
                  "dropzone tw-bg-[#E8F0FE] tw-flex justify-content-center tw-border tw-rounded-[50px] tw-h-[300px] tw-w-full",
              })}
            >
              <input {...getInputProps()} />
              <div className="d-flex align-items-center justify-content-center flex-column">
                {image ? (
                  <section className="dropzone slide_image tw-bg-[#E8F0FE] tw-flex justify-content-center tw-border tw-rounded-[50px] tw-h-[300px] tw-w-full">
                    <img
                      src={
                        file
                          ? URL.createObjectURL(file)
                          : `${process.env.REACT_APP_PUBLIC_URL}/cms/slide/image/${slide.image}`
                      }
                      alt="slider logo"
                    />
                  </section>
                ) : (
                  <section>
                    {/* <FiDownloadCloud size={64} /> */}
                    <DriveFolderUploadOutlinedIcon />
                    <span className=" tw-text-[12px] ">
                      Glisser déposer ici l'image
                    </span>
                    <p className="text-secondary tw-text-[12px]">
                      Or cliquer ici pour{" "}
                      <a href="/" onClick={(e) => e.preventDefault()}>
                        téléverser
                      </a>{" "}
                      depuis votre machine
                    </p>
                  </section>
                )}
              </div>
            </div>

            <br />
            <button
              disabled={load}
              type="submit"
              className="tw-text-sm tw-font-semibold tw-h-[35px] tw-px-3 tw-rounded-md tw-text-white tw-bg-[#E7075B]"
              onClick={handleSubmit(onSubmit)}
            >
              {load ? (
                <CircularProgress size={16} sx={{ color: "white" }} />
              ) : (
                "ENREGISTRER"
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditSlider;
