import React, { useEffect } from "react";
// import {useDispatch} from 'react-redux';

const NotFound = () => {
  return (
    <>
      <div className="tw-h-screen tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center tw-bg-[#FFF8F8]">
        <h1 className="tw-text-9xl tw-font-extrabold tw-text-slate-500 tw-tracking-widest">
          404
        </h1>
        <div className="tw-bg-[#E7075B] tw-text-white tw-px-2 tw-text-sm tw-rounded tw-rotate-12 tw-absolute">
          Page Not Found
        </div>
      </div>
    </>
  );
};

export default NotFound;
