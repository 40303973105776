// ** React Imports
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import classnames from "classnames";

import Breadcrumbs from "../../@core/components/breadcrumbs";
import { NotificationManager } from "react-notifications";
import { CMSApi } from "../../api/cmsApi";

const Cgu = () => {
  const navigate = useNavigate();

  const [data, setData] = useState({
    title: "Condition Générale d'utilisation",
    subtitle: "CGU",
    content: "",
  });

  const [load, setLoad] = useState(true);
  const [activeTab, SetActiveTab] = useState("1");

  const getCgu = async () => {
    setLoad(true);
    const res = await CMSApi.getCGU();
    setLoad(false);

    if (res.success) {
      setData({
        title: "Condition Générale d'utilisation",
        subtitle: "CGU",
        content: res.data.cgu,
        content_english: res.data.english_cgu || "Vide",
      });
    } else {
      NotificationManager.error("Erreur de chargement du CGU", "", 3000);
    }
  };

  const toggle = (tab) => {
    if (activeTab !== tab) {
      SetActiveTab(tab);
    }
  };

  useEffect(() => {
    getCgu();
  }, []);

  return (
    <div className="datatable">
      <div className=" tw-w-full tw-my-3">
        <Breadcrumbs title="CGU" />

        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => {
                toggle("1");
              }}
            >
              CGU FRANCAIS
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => {
                toggle("2");
              }}
            >
              CGU ANGLAIS
            </NavLink>
          </NavItem>
        </Nav>

        <div className="blog-wrapper">
          <div className="content-detached ">
            <div className="content-body">
              <Row>
                <Col sm="12">
                  <Card className="mb-3">
                    {/* <CardImg src={data?.blog.img} className='img-fluid' top /> */}

                    <CardBody>
                      <CardTitle tag="h4">{data?.title}</CardTitle>

                      {load ? (
                        <div>Loading...</div>
                      ) : (
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              activeTab === "1"
                                ? data?.content
                                : data.content_english,
                          }}
                        ></div>
                      )}

                      <hr className="my-2" />

                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <button
                            onClick={() =>
                              navigate(`/edit`, {
                                state: {
                                  ...data,
                                  lng: activeTab === "1" ? "fr" : "en",
                                },
                              })
                            }
                            className=" tw-w-[150px] tw-text-white tw-font-semibold tw-h-[35px] tw-rounded-md tw-justify-center tw-items-center "
                          >
                            Modifier
                          </button>

                          <div className="d-flex align-items-center" />
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cgu;
