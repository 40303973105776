import React from "react";
import Sidebar from "../components/sidebar/Sidebar";
import Navbar from "../components/navbar/Navbar";
import DrawerRight from "../components/drawer/drawerRight";

export default function LayoutAfterLog({ children }) {
  return (
    <div className=" tw-flex tw-flex-row tw-h-screen tw-w-screen ">
      <div className=" tw-w-[250px]">
        <Sidebar />
      </div>

      <div className=" tw-flex tw-flex-1 tw-flex-col tw-overflow-y-scroll">
        <div className=" tw-w-full tw-z-[999] tw-bg-white tw-sticky tw-top-0">
          <Navbar />
        </div>

        {children}

        <DrawerRight />
      </div>
    </div>
  );
}
