import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { NotificationManager } from "react-notifications";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import useFetch from "../../hooks/useFetch";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { useAuthUser } from "react-auth-kit";

const Datatable2 = ({ columns }) => {
  const auth = useAuthUser();
  const location = useLocation();
  const path = location.pathname.split("/")[1];
  const [list, setList] = useState([]);
  const { data, loading, error } = useFetch(`/${path}`, auth().token);

  const path_name = {
    promo: "code promo",
    containers: "conteneur",
    organizers: "organisateur",
  };

  useEffect(() => {
    setList(data);
  }, [data]);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_PUBLIC_URL}/${path}/${id}`, {
        headers: {
          Authorization: `Bearer ${auth().token}`,
        },
      });
      setList(list.filter((item) => item.id !== id));
      NotificationManager.success(
        `${path_name[path]} supprimé avec succès`,
        "Success",
        3000,
      );
    } catch (err) {
      console.log(err);
      NotificationManager.error(
        `${err.response.data.message}`,
        `Oups ! La supression a échoué`,
        3000,
      );
    }
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 300,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            {path !== "promo" && (
              <Link
                to={`/${path}/${params.row.id}`}
                style={{ textDecoration: "none" }}
              >
                <div className="btn-fushia tw-justify-center tw-items-center tw-ml-3 tw-flex tw-flex-row tw-text-white tw-text-[12px] tw-rounded-lg tw-h-[35px] tw-px-[15px] ">
                  Voir / Modifier
                </div>
              </Link>
            )}
            <div
              className="deleteButton"
              onClick={() => handleDelete(params.row.id)}
            >
              Suprimer
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <div className="datatable">
      <div className="datatableTitle">
        Liste des {path_name[path]}s
        {["GM", "CA", "EM", "RMC"].includes(auth().role) && (
          <Link
            to={`/${path}/new`}
            className="btn-fushia tw-justify-center tw-items-center tw-ml-3 tw-flex tw-flex-row tw-text-white tw-text-[14px] tw-rounded-lg tw-h-[35px] tw-px-[15px] "
          >
            Créer un {path_name[path]}
          </Link>
        )}
      </div>
      <DataGrid
        className="datagrid"
        rows={list}
        columns={columns.concat(
          ["GM", "CA", "EM", "RMC"].includes(auth().role) ? actionColumn : [],
        )}
        pageSize={9}
        rowsPerPageOptions={[9]}
        getRowId={() => uuidv4()}
      />
    </div>
  );
};

export default Datatable2;
