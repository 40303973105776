import { useState } from "react";
import { useDispatch, batch } from "react-redux";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { NotificationManager } from "react-notifications";
import { useAuthUser } from "react-auth-kit";

import { AdminApi } from "../../../api/adminApi";

const AddAdminDialog = ({ data }) => {
  const auth = useAuthUser();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [load, setload] = useState(false);

  const handleClose = () => {
    dispatch({ type: "setDialog", payload: { active: false, view: null } });
    dispatch({
      type: "setDrawer",
      payload: {
        active: false,
        view: null,
        value: null,
      },
    });
  };

  const handleSubmit = async () => {
    setload(true);
    let res;

    if (data?.password) {
      res = await AdminApi.createAdmin(data, auth().token);
    } else {
      res = await AdminApi.putAdmin(data, auth().token);
    }

    setload(false);

    if (res.success) {
      if (data?.password) {
        batch(() => {
          dispatch({
            type: "setDialog",
            payload: { active: false, view: null },
          });
          dispatch({ type: "AddAdmin", value: res.data });
          dispatch({
            type: "setDrawer",
            payload: {
              active: false,
              view: null,
              value: null,
            },
          });
        });

        NotificationManager.success(
          "Administrateur ajouté avec succès ",
          "",
          3000,
        );
      } else {
        batch(() => {
          dispatch({
            type: "setDialog",
            payload: { active: false, view: null },
          });
          dispatch({ type: "PutAdmin", value: { ...res.data, id: data.id } });
          dispatch({
            type: "setDrawer",
            payload: {
              active: false,
              view: null,
              value: null,
            },
          });
        });

        NotificationManager.success(
          "Administrateur modifié avec succès ",
          "",
          3000,
        );
      }
    } else {
      NotificationManager.error(`${res.message}`, "", 3000);
      dispatch({
        type: "setDrawer",
        payload: {
          active: false,
          view: null,
          value: null,
        },
      });
      dispatch({ type: "setDialog", payload: { active: false, view: null } });
    }
  };

  return (
    <>
      <DialogTitle
        sx={{
          backgroundColor: "#E7075B",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <span className="tw-font-bold tw-text-[15px] tw-text-white">
          AJOUTER UN UTILISATEUR
        </span>
      </DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div className="tw-text-md tw-mt-3">
            Êtes-vous sûr(e) de vouloir ajouter{" "}
            <span className=" tw-font-bold">
              {data.first_name + " " + data.last_name}
            </span>{" "}
            ? <br />
          </div>
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <button
          disabled={load}
          className="tw-text-sm tw-font-semibold tw-h-[35px] tw-px-3 tw-rounded-md tw-text-slate-700"
          onClick={() => handleClose()}
        >
          NON
        </button>
        <button
          disabled={load}
          className="tw-text-sm tw-font-semibold tw-h-[35px] tw-px-3 tw-rounded-md tw-text-white tw-bg-green-500"
          onClick={() => handleSubmit()}
        >
          {load ? (
            <CircularProgress size={16} sx={{ color: "white" }} />
          ) : (
            "OUI"
          )}
        </button>
      </DialogActions>
    </>
  );
};

export default AddAdminDialog;
